// @ts-nocheck
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { registerLocaleData } from '@angular/common';

import fr from '@angular/common/locales/fr';
import en from '@angular/common/locales/en';
import { appConfig } from './app/app.config';
import * as Sentry from '@sentry/angular';

const errorsToExclude = /auth|-100/;

Sentry.init({
  dsn: 'https://7fc69f128ee57ec279081a93e67d09fc@o4507979817877504.ingest.de.sentry.io/4507979821023312',
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    Sentry.browserTracingIntegration(),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    Sentry.replayIntegration(),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
  tracesSampleRate: 1.0,
  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [
    'localhost',
    /^http:\/\/local\.stiilt\.fr:4200\/api/,
    /^https:\/\/devapp\.fpsnavigator\.fr\/api/,
    /^https:\/\/app\.fpsnavigator\.com\/api/,
  ],
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  // Learn more at
  // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: window['__env'].sentry_environment,
  release: window['__env'].version,
  beforeSend: (event, hint) => {
    if (
      hint.originalException &&
      (errorsToExclude.test(hint.originalException.code) || errorsToExclude.test(hint.originalException.message))
    ) {
      return null; // Ignore the event
    }
    if (window['__env'].sentry_environment === 'dev') {
      console.log(`Sentry Error not sent since env is : ${window['__env'].sentry_environment}`);
      return null;
    } else {
      return event; // Send the event to Sentry
    }
  },
});

registerLocaleData(fr);
registerLocaleData(en);

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
