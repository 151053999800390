import { Component, Input } from '@angular/core';
import { ToastType } from '../../types/toast-type.enum';
import { SharedModule } from '@shared/shared.module';

@Component({
    selector: 'stiilt-toast',
    imports: [SharedModule],
    templateUrl: './toast.component.html',
    styles: ``
})
export class ToastComponent {
  @Input() public closeFn!: ($event: MouseEvent) => void;
  @Input() type!: ToastType;
  @Input() message!: string;
  protected readonly ToastType = ToastType;
}
