import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnvServiceProvider } from '@core/providers/env.service.provider';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpListenerService } from '@core/interceptors/http-listener.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
  providers: [EnvServiceProvider, { provide: HTTP_INTERCEPTORS, useClass: HttpListenerService, multi: true }],
})
export class CoreModule {}
