import { Component, Input } from '@angular/core';
import { Confirmation } from 'primeng/api';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'stiilt-confirm',
  imports: [SharedModule],
  templateUrl: './confirm.component.html',
  styles: ``,
})
export class ConfirmComponent {
  @Input() public onAccept!: () => void;
  @Input() public onReject!: () => void;
  @Input() public confirmation!: Confirmation;
}
