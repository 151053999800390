<div class="h-screen bg-subtle-blue">
  <router-outlet />
</div>

<!--Here for now because Z-index problem.-->
<p-toast *ngFor="let type of toastTypes" position="top-center" [key]="type" [baseZIndex]="99999"
         styleClass="rounded-lg">
  <ng-template let-message #headless let-closeFn="closeFn">
    <stiilt-toast [closeFn]="closeFn" [type]="type" [message]="message.detail"></stiilt-toast>
  </ng-template>
</p-toast>

<p-confirmdialog #cd>
  <ng-template #headless let-message let-onAccept="onAccept" let-onReject="onReject">
    <stiilt-confirm [confirmation]="message" [onAccept]="onAccept" [onReject]="onReject"></stiilt-confirm>
  </ng-template>
</p-confirmdialog>

