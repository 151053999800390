<div
  class="relative transform overflow-hidden px-4 pt-5 pb-4 text-left sm:w-full sm:max-w-lg sm:p-6">
  <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
    <p-button icon="fa-solid fa-xmark" [rounded]="true" [text]="true" />
  </div>
  <div class="sm:flex sm:items-start">
    <div
      class="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:size-10">
      <svg class="size-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
           aria-hidden="true" data-slot="icon">
        <path stroke-linecap="round" stroke-linejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
      </svg>
    </div>
    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
      <h3 class="text-base font-semibold text-gray-900" id="modal-title">{{ confirmation.header }}</h3>
      <div class="mt-2">
        <p class="text-sm text-gray-500">{{ confirmation.message }}</p>
      </div>
    </div>
  </div>
  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse gap-3">
    <p-button size="small" iconPos="right" [icon]="confirmation.acceptIcon" [label]="confirmation.acceptLabel"
              [class]="confirmation.acceptButtonStyleClass" (click)="onAccept()"></p-button>

    <p-button *ngIf="confirmation.rejectVisible" size="small" iconPos="right" [icon]="confirmation.rejectIcon" [label]="confirmation.rejectLabel"
              (click)="onReject()" variant="outlined"></p-button>
  </div>
</div>
