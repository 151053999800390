import { Component } from '@angular/core';
import { ToastType } from '@shared/types/toast-type.enum';
import { UpdateService } from '@core/services/update.service';
import { ToastComponent } from '@shared/components/toast/toast.component';
import { SharedModule } from '@shared/shared.module';
import { ConfirmComponent } from '@shared/components/confirm/confirm.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  imports: [SharedModule, ToastComponent, ConfirmComponent],
})
export class AppComponent {
  public title = 'fps-navigator-frontent';
  public toastTypes = Object.values(ToastType) as ToastType[];
  constructor(private readonly update: UpdateService) {}
}
