import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  inject,
  isDevMode,
  provideAppInitializer,
} from '@angular/core';
import { provideRouter, Router } from '@angular/router';
import { routes } from './app.routes';
import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/compat/storage';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { provideServiceWorker } from '@angular/service-worker';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { EnvServiceFactory } from '@core/providers/env.service.provider';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { providePrimeNG } from 'primeng/config';
import { FPSPreset } from '@core/theme/FPSPreset';
import { provideTransloco, TranslocoService } from '@jsverse/transloco';
import { preloadUser, TranslocoHttpLoader } from '@core/transloco-loader';
import { provideTranslocoLocale } from '@jsverse/transloco-locale';
import { AvailableLanguagesEnum } from '@shared/types/available-langs.enum';
import * as Sentry from '@sentry/angular';
import { TraceService } from '@sentry/angular';

const dynamicEnv = EnvServiceFactory();

export const appConfig: ApplicationConfig = {
  providers: [
    // Animations
    provideAnimationsAsync(),
    providePrimeNG({
      theme: {
        preset: FPSPreset,
        options: {
          darkModeSelector: false,
          cssLayer: {
            name: 'primeng',
            order: 'tailwind-base, primeng, tailwind-utilities',
          },
        },
      },
    }),

    MessageService,
    ConfirmationService,
    DatePipe,
    importProvidersFrom(
      CoreModule,
      SharedModule,
      AngularFireModule.initializeApp(dynamicEnv.firebaseCredentials),
      AngularFireAuthModule,
      AngularFireStorageModule,
      AngularFireDatabaseModule,
    ),

    importProvidersFrom(CoreModule, SharedModule),

    // Configuration Firebase Storage
    {
      provide: BUCKET,
      useValue: dynamicEnv.firebaseCredentials.storageBucket,
    },

    // Service Worker
    provideServiceWorker('ngsw-worker.js?version=latest', {
      enabled: true,
      registrationStrategy: 'registerWhenStable:30000',
    }),

    // HTTP Client
    provideHttpClient(withInterceptorsFromDi()),

    // Router
    provideRouter(routes),

    provideHttpClient(),
    provideTranslocoLocale({
      langToLocaleMapping: {
        en: 'en-UK',
        fr: 'fr-FR',
      },
    }),
    provideTransloco({
      config: {
        availableLangs: [AvailableLanguagesEnum.EN, AvailableLanguagesEnum.FR],
        defaultLang: AvailableLanguagesEnum.FR,
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideAppInitializer(() => {
      const transloco = inject(TranslocoService);
      preloadUser(transloco);
    }),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    provideAppInitializer(() => {
      inject(TraceService);
    }),
  ],
};
