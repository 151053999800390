import { inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader, TranslocoService } from '@jsverse/transloco';
import { HttpClient } from '@angular/common/http';
import { AvailableLanguagesEnum } from '@shared/types/available-langs.enum';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

export function preloadUser(transloco: TranslocoService) {
  navigator.language.includes(AvailableLanguagesEnum.EN)
    ? transloco.setActiveLang(AvailableLanguagesEnum.EN)
    : transloco.setActiveLang(AvailableLanguagesEnum.FR);
}
